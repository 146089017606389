<template>
  <window-content v-if="!loading"> <!-- v-if="!loading && !errors && !success" -->
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border size="2" v-model="cliente.id" disable/>
          </erp-s-field>
        </e-col>

        <e-col>
          <plano-select label="Plano:" size="2" field-view="lr" simple-border="" v-model="cliente.plano"/>
        </e-col>

        <e-col>
          <servidor-select label="Servidor:" size="2" field-view="lr" simple-border="" v-model="cliente.servidor"/>
        </e-col>

        <e-col style="max-width: 260px">
          <status-select size="2" label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                         v-model="cliente.status"/>
        </e-col>

      </e-row>

      <e-row class="erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              :label="cliente.pessoa.type === 1 ? 'Nome completo:' : 'Razão social:'"
              label-width="110px"
          >
            <erp-input size="2" simple-border v-model="cliente.pessoa.name"/><a v-if="cliente.id" @click="gotoPerson(cliente.pessoa)" class="absolute-right m-t-xs m-r-sm text-green-8 cursor-pointer"><i class="fa fa-eye" /></a>
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 280px">
          <tipo-pessoa-select size="2" label="Tipo Pessoa:" field-view="lr" simple-border=""
                              v-model="cliente.pessoa.type"/>
        </e-col>

        <e-col style="max-width: 260px">
          <erp-s-field
              view="lr"
              :label="cliente.pessoa.type === 1 ? 'CPF:' : 'CNPJ:'"
              label-width="40px"
          >
            <erp-input size="2" simple-border v-model="cliente.pessoa.document" v-mask="'###.###.###-##'"
                       v-if="cliente.pessoa.type === 1"/>
            <erp-input simple-border v-model="cliente.pessoa.document" v-mask="'##.###.###/####-##'" v-else/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="Dominio:"
              label-width="110px"
          >
            <erp-input size="2" simple-border v-model="cliente.dominio"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label="Mensalidade:"
          >
            <erp-input size="2" v-money="money" simple-border v-model.lazy="cliente.mensalidade"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="E-mail:"
              label-width="110px"
          >
            <erp-input size="2" simple-border v-model="cliente.pessoa.emails[0].email"/>
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Celular:"
              label-width="50px"
          >
            <erp-input size="2" simple-border v-model="cliente.pessoa.phoneNumbers[0].phoneNumber"
                       v-mask="'(##) # ####-####'"/>
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Residencial:"
              label-width="70px"
          >
            <erp-input size="2" simple-border v-model="cliente.pessoa.phoneNumbers[1].phoneNumber"
                       v-mask="'(##) ####-#####'"/>
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Comercial:"
              label-width="70px"
          >
            <erp-input size="2" simple-border v-model="cliente.pessoa.phoneNumbers[2].phoneNumber"
                       v-mask="'(##) ####-#####'"/>
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <erp-tabs ref="tab" @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
      <erp-tab-item name="contato">Contato</erp-tab-item>
      <erp-tab-item name="acesso">Definições</erp-tab-item>
      <erp-tab-item name="financeiro" v-if="cliente.id">Financeiro</erp-tab-item>
      <erp-tab-item name="setup" v-if="setup">Instalação</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" :class="{'flex col-grow': tabActive === 'setup'}" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row v-if="isPessoaFisica" mr>
              <e-col style="min-width: 280px">
                <erp-s-field
                    view="lr"
                    label="RG:"
                    label-width="90px"
                >
                  <erp-input size="2" v-model="cliente.pessoa.documents[0].identifier"/>
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Emissor:"
                >
                  <erp-input size="2" v-model="cliente.pessoa.documents[0].agentDispatcher"/>
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Expedição:"
                >
                  <erp-input size="2" v-model="cliente.pessoa.documents[0].expedition"/>
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isPessoaFisica" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nascimento:"
                    label-width="90px"
                >
                  <erp-input size="2" v-model="cliente.pessoa.birthDate" v-mask="'##/##/####'"/>
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nacionalidade:"
                >
                  <erp-input size="2" v-model="cliente.pessoa.nationality"/>
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Sexo:"
                >
                  <erp-select size="2" placeholder="Selecione"
                              :options="[{label: 'Masculino', value: 1},{label: 'Feminino', value: 2}]"
                              v-model="cliente.pessoa.gender"/>
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row mr>
<!--              <e-col style="max-width: 294px">
                <erp-s-field
                    view="lr"
                    label="Status:"
                    label-width="90px"
                >
                  <erp-select size="2" placeholder="Selecione"
                              :options="statusCliente"
                              v-model="cliente.status"/>
                </erp-s-field>
              </e-col>-->
              <e-col>
                <erp-s-field
                    view="lr"
                    label="M./Status:"
                    label-width="90px"
                >
                  <erp-input size="2" v-model="cliente.statusMessage"/>
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row mr class="m-t">
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Descrição:"
                    class="wrap label-wrap"
                    label-width="90px"
                >
                  <textarea class="w-full erp-input wrapper-xs" rows="4"
                            v-model="cliente.observacao"></textarea>
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row mr class="m-t">
              <e-col>
                <erp-s-field
                    view="lr"
                    label=""
                    class="wrap label-wrap"
                    label-width="90px"
                >
                  <u-checkbox v-model="instalar" color="positive" label="Criar instância" class="text-uppercase"/>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
          <!--          <e-col class="m-l-xxl" style="min-width: 216px; max-width: 216px">
                      <imagem label="Logomarca" legend="De preferência PNG 200x80px" ref="imagem" :image="cliente.image"
                              @update="updateImagem" @toDelete="deleteImagemEvent" />
                    </e-col>-->
        </e-row>
        <!--        <e-row class="m-t">
                  <e-col>
                    <div class="font-bold m-b-sm font-11 text-uppercase">Após o cadastro ou alteração:</div>
                    <e-row>
                      <e-col>
                        <erp-checkbox class="flex no-wrap items-center m-r" v-model="cliente.enviarBoasVindas">
                          <label class="no-select text-nowrap font-bold">
                            <span class="m-l-xs text-nowrap">Enviar e-mail de boas vindas</span>
                          </label>
                        </erp-checkbox>
                      </e-col>
                    </e-row>
                  </e-col>
                </e-row>-->
      </div>
      <div v-if="tabActive === 'contato'" class="tab-flex wrapper m-t">
        <endereco auto-null :pessoa="cliente.pessoa"/>
        <e-row class="m-t">
          <e-col>
            <e-row v-for="(telefone, index) in cliente.pessoa.phoneNumbers" :key="telefone.id">
              <e-col>
                <e-row v-if="index === 0" mr>
                  <e-col style="max-width: 280px">
                    <erp-s-field
                        view="lr"
                        label=""
                        label-width="90px"
                    >
                      <div class="font-11 font-bold m-b">Telefones</div>
                    </erp-s-field>
                  </e-col>
                </e-row>
                <e-row v-if="index > 2" class="m-b-sm" mr>
                  <e-col style="max-width: 280px">
                    <tipo-telefone-select field-view="lr" label-width="90px" v-model="telefone.purpose"/>
                  </e-col>
                  <e-col class="flex items-center">
                    <a class="text-negative" @click="removeTelefone(telefone)">remover este telefone</a>
                  </e-col>
                </e-row>
                <e-row class="m-t-n" mr>
                  <e-col style="max-width: 280px">
                    <erp-s-field
                        view="lr"
                        :label="getTelefoneLabel(index)"
                        label-width="90px"
                    >
                      <erp-input v-if="index > 2" size="2" v-model="telefone.phoneNumber" v-mask="'(##) # ####-####'"/>
                      <div v-else>{{ telefone.phoneNumber|formataTelefone }}</div>
                    </erp-s-field>
                  </e-col>
                </e-row>
              </e-col>
            </e-row>
            <e-btn @click="adicionarTelefone" style="margin-left: 102px" label="Adicionar novo telefone"/>
            <!-- -->
            <e-col>
              <e-row class="m-t-lg" mr>
                <e-col style="max-width: 280px">
                  <erp-s-field
                      view="lr"
                      label=""
                      label-width="90px"
                  >
                    <div class="font-11 font-bold">Outros contatos</div>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row v-for="(contato, index) in cliente.pessoa.contacts" :key="contato.id">
                <e-col>
                  <e-row class="m-b-sm" mr>
                    <e-col style="max-width: 280px">
                      <tipo-contato-select field-view="lr" size="2" label="Tipo:" label-width="90px"
                                           v-model="contato.purpose"/>
                    </e-col>
                    <e-col class="flex items-center">
                      <a class="text-negative" @click="removeContato(contato)">remover este contato</a>
                    </e-col>
                  </e-row>
                  <e-row class="m-t-n" mr>
                    <e-col style="max-width: 340px">
                      <erp-s-field
                          view="lr"
                          label="Contato"
                          label-width="90px"
                      >
                        <erp-input size="2" v-model="contato.value"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                </e-col>
              </e-row>
              <e-btn @click="adicionarContato" style="margin-left: 102px" label="Adicionar novo contato"/>
            </e-col>
          </e-col>
          <e-col>
            <e-row mr>
              <e-col style="max-width: 280px">
                <erp-s-field
                    view="lr"
                    label=""
                    label-width="90px"
                >
                  <div class="font-11 font-bold m-b">E-mails</div>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row class="m-t-n" mr>
              <e-col style="max-width: 340px">
                <erp-s-field
                    view="lr"
                    label="Principal:"
                    label-width="90px"
                >
                  <erp-input size="2" v-model="cliente.pessoa.emails[0].email"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row v-for="(email, index) in cliente.pessoa.emails" :key="email.id">
              <e-col v-if="index > 0">
                <e-row class="m-b-sm" mr>
                  <e-col style="max-width: 280px">
                    <tipo-email-select field-view="lr" size="2" label=" " label-width="90px" v-model="email.purpose"/>
                  </e-col>
                  <e-col class="flex items-center">
                    <a class="text-negative" @click="removeEmail(email)">remover este email</a>
                  </e-col>
                </e-row>
                <e-row class="m-t-n" mr>
                  <e-col style="max-width: 340px">
                    <erp-s-field
                        view="lr"
                        label=""
                        label-width="90px"
                    >
                      <erp-input size="2" v-model="email.email"/>
                    </erp-s-field>
                  </e-col>
                </e-row>
              </e-col>
            </e-row>
            <e-btn @click="adicionarEmail" style="margin-left: 102px" label="Adicionar novo e-mail"/>
          </e-col>
        </e-row>
      </div>
      <div v-show="tabActive === 'acesso'" class="wrapper m-t">
        <e-row mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Configuração:"
                label-width="110px"
            >
              <!--<div class="erp-textarea" style="min-height: 200px" ref="configEditor" :contenteditable="true" v-html="cliente.configuracao"></div>-->
              <code-editor v-model="cliente.configuracaoString" :languages="[['json']]" :display_language="false" class="source-code" width="100%" height="400px" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
      <div v-if="tabActive === 'financeiro'" class="tab-flex wrapper m-t">
        Último pagamento: {{cliente.ultimoPagamento|formatDate}}

        <cliente-financeiro class="m-t fin-cliente" :cliente="cliente" />
      </div>
      <div v-if="tabActive === 'setup'" class="wrapper m-t flex col-grow">
        <div class="erp-terminal">
          <vue-typed-js :typeSpeed="5" :class="{hideCursor: !cursor.step1}"
                        :strings="['Cliente registrado com sucesso!']" @onStringTyped="() => cursor.step1 = false">
            <div class="typing font-bold text-positive"></div>
          </vue-typed-js>
          <vue-typed-js v-if="!cursor.step1" :typeSpeed="5" :class="{hideCursor: !cursor.step2}"
                        :strings="['Iniciando configuração da instância, aguarde...']">
            <div class="typing"></div>
          </vue-typed-js>
          <div v-for="message in buffer" :key="message.id" class="m-t-xs">
            [{{ message.date.date|formatDate }}] {{ message.message }}
          </div>
        </div>
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <!--<div class="flex items-center justify-center font-11">
        <div class="m-l-xs">
          Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
          status para Em breve.
        </div>
      </div>-->
      <div class="inline-block window-actions text-right self-end col-grow">
<!--        <u-btn v-if="cliente.id" class="m-r-sm pull-left" color="negative" label="Cadastrar novo" icon="plus-add" flat icon-type="fa" icon-style="light"
               no-caps
               @click="reset"/>-->

        <u-btn class="m-r-sm" color="white" label="Cancelar" icon="chevron-left" flat icon-type="fa" icon-style="light"
               no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn :loading="loading || (setup && setupEnd === 'in-progress')" color="positive" label="Salvar (F2)"
               icon="save" icon-type="fa" icon-style="light" no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save"/>
      </div>
    </window-footer>
  </window-content>
  <!--  <window-success v-else-if="success">
      <div class="text-center">
        <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
      </div>
      <h5 v-if="!id" class="m-t">Cliente registrado com sucesso!</h5>
      <h5 v-else class="m-t">Cliente atualizado com sucesso!</h5>
      <div class="text-center m-t">
        <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
      </div>
    </window-success>
    <window-loading :errors="errors" :status="status" v-else />-->
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  // WindowHeader,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  // HelperInputBtn,
  // ErpBox,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
// import WindowLoading from '../../../layout/window/Loading'
// import TimerCloseWindow from '../../../layout/window/TimerClose'
// import WindowSuccess from '../../../layout/window/Success'
import {date, UCheckbox} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {
  find,
  newCliente,
  updateCliente,
  setup,
  setupLogBuffer
} from '../../../../../domain/gerencia/clientes/services/index'
import {removePersonObject} from '@/domain/pessoa/domain'
import DatetimeInput from '../../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../../reuse/input/DefaultAsyncSelect'
import StatusSelect from '../include/StatusClienteSelect'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import PlanoSelect from '../include/PlanoSelect'
import ServidorSelect from '../include/ServidorSelect'
import TipoEnderecoSelect from '../../../../pessoa/components/include/TipoEnderecoSelect'
import CidadeSelect from '../../../../pessoa/components/include/CidadeSelect'
import UfSelect from '../../../../pessoa/components/include/UfSelect'
import TipoTelefoneSelect from '../../../../pessoa/components/include/TipoTelefoneSelect'
import TipoEmailSelect from '../../../../pessoa/components/include/TipoEmailSelect'
import TipoContatoSelect from '../../../../pessoa/components/include/TipoContatoSelect'
import {adjustPurposes} from '@/domain/pessoa/helpers/Purpose'
import {TIPO_PESSOA_JURIDICA} from '@/domain/pessoa/helpers/Status'
import TipoPessoaSelect from '../../../../pessoa/components/include/TipoPessoaSelect'
import Endereco from '../../../../pessoa/components/include/EnderecoV2'

import {mockEndereco} from 'components/pessoa/mock/endereco'
import {mockTelefone} from 'components/pessoa/mock/telefone'
import {mockEmail} from 'components/pessoa/mock/email'
import {mockContato} from 'components/pessoa/mock/contato'
import {mockDocument} from 'components/pessoa/mock/document'
import CodeEditor from "simple-code-editor"
import StatusCliente from "components/gerencia/cliente/helpers/StatusCliente"
import ClienteFinanceiro from "components/gerencia/cliente/components/include/Financeiro";
import GlobalPersonMixin from "components/pessoa/globalPersonMixin";

let mockCliente = {
  id: null,
  dominio: null,
  configuracao: {
    instancia: null
  },
  configuracaoString: '',
  observacao: null,
  setup: null,
  mensalidade: null,
  plano: null,
  servidor: null,
  pessoa: {
    id: null,
    name: null,
    type: 1,
    document: null,
    birthDate: null,
    nationality: null,
    gender: null,
    addresses: [JSON.parse(JSON.stringify(mockEndereco))],
    phoneNumbers: [JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone))],
    emails: [JSON.parse(JSON.stringify(mockEmail))],
    contacts: [JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato))],
    documents: [JSON.parse(JSON.stringify(mockDocument))]
  },
  enviarBoasVindas: true,
  solicitarCriacaoSenha: true,
  status: 'active',
  statusMessage: null,
  active: true
}

const initialState = function () {
  return {
    money: REAL_BRL,
    tabActive: 'principal',
    loading: !!this.getId(),
    errors: null,
    success: false,
    status: null,
    deleteImagem: false,
    cliente: JSON.parse(JSON.stringify(mockCliente)),
    semEndereco: true,
    setup: false,
    setupEnd: null,
    setupTempo: 0,
    cursor: {
      step1: true,
      step2: true,
    },
    buffer: [],
    instalar: true,
    prevent: {
      configuracaoUpdate: false,
      configuracaoStringUpdate: false
    }
  }
}

export default {
  name: 'ClienteWindow',
  directives: {money: VMoney},
  mixins: [GlobalPersonMixin],
  props: ['id', 'router'],
  data() {
    return initialState.call(this)
  },
  mounted() {
    this.load()
  },
  beforeDestroy() {
    this.$logBufferInterval && clearInterval(this.$logBufferInterval)
  },
  computed: {
    isPessoaFisica() {
      return this.cliente.pessoa.type === 1
    },
    isEmpresa() {
      return this.cliente.pessoa.type === 2
    },
    statusCliente () {
      return StatusCliente
    }
  },
  watch: {
    /*'cliente.configuracao' (v) {
      console.log('cliente.configuracao test')
      if (this.prevent.configuracaoStringUpdate) return
      console.log('cliente.configuracao update')
      this.prevent.configuracaoUpdate = true
      this.configuracaoUpdateTimeout && clearTimeout(this.configuracaoUpdateTimeout)
      this.configuracaoUpdateTimeout = setTimeout(() => {
        this.prevent.configuracaoUpdate = false
      }, 500)
      try {
        const s = JSON.stringify(v, null, 4)
        this.cliente.configuracaoString = s
      } catch (e) {
      }
    },*/
    'cliente.configuracaoString' (v) {
      /*console.log('cliente.configuracaoString test')
      if (this.prevent.configuracaoUpdate) return
      console.log('cliente.configuracaoString update')
      this.configuracaoStringUpdate && clearTimeout(this.configuracaoStringUpdate)
      this.prevent.configuracaoStringUpdate = true
      setTimeout(() => {
        this.prevent.configuracaoStringUpdate = false
      }, 500)*/
      try {
        const s = JSON.parse(v)
        this.cliente.configuracao = s
      } catch (e) {
      }
    }
  },
  methods: {
    getId () {
      return this.cliente && this.cliente.id ? this.cliente.id : this.id
    },
    reset () {
      Object.assign(this.$data, initialState.call(this))
    },
    load() {
      this.loading = !!this.getId()
      this.instalar = !!!this.getId()
      this.getId() && find(this.getId())
          .then(response => {
            let data = response.data

            console.log(typeof data.configuracao)
            if (typeof data.configuracao !== 'object' || Array.isArray(data.configuracao)) {
              data.configuracao = {
                instancia: null
              }
            }

            data.configuracaoString = JSON.stringify(data.configuracao, null, 4)

            if (data.plano && data.plano.id) {
              data.plano = data.plano.id
            }

            if (data.servidor && data.servidor.id) {
              data.servidor = data.servidor.id
            }

            if (data.pessoa.birthDate && data.pessoa.birthDate.date) {
              data.pessoa.birthDate = date.formatDate(data.pessoa.birthDate.date, 'DD/MM/YYYY')
            }
            // let mock = JSON.parse(JSON.stringify(mockCliente))
            // console.log(mock)
            // this.cliente = Object.assign(mock, data)
            // console.log(this.cliente)
            if (!data.pessoa.addresses || data.pessoa.addresses.length === 0) {
              data.pessoa.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
            }

            if (!data.pessoa.documents || data.pessoa.documents.length === 0) {
              data.pessoa.documents = [JSON.parse(JSON.stringify(mockDocument))]
            }

            if (!data.pessoa.phoneNumbers || data.pessoa.phoneNumbers.length < 3) {
              if (!Array.isArray(data.pessoa.phoneNumbers)) {
                data.pessoa.phoneNumbers = []
              }
              console.log(data.pessoa.phoneNumbers.length)
              const len = 3 - Number(data.pessoa.phoneNumbers.length)
              for (let i = 0; i < len; i++) {
                data.pessoa.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
              }
              console.log(data.pessoa.phoneNumbers)
            }

            if (!data.pessoa.emails || data.pessoa.emails.length === 0) {
              data.pessoa.emails = [JSON.parse(JSON.stringify(mockEmail))]
            }
            adjustPurposes([
              data.pessoa.addresses,
              data.pessoa.phoneNumbers,
              data.pessoa.contacts,
              data.pessoa.emails
            ])

            data.mensalidade = 'R$ ' + this.$options.filters.moeda(data.mensalidade || 0)

            this.cliente = data
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar o cliente, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    changeTab({tab}) {
      this.tabActive = tab
    },
    save() {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.cliente))

      // Ajusta dados
      let phones = []
      this.cliente.pessoa.phoneNumbers.forEach((phone, index) => {
        if (phone.phoneNumber && String(phone.phoneNumber).length > 4) {
          phones.push(phone)
        }
      })
      data.pessoa.phoneNumbers = phones

      let contacts = []
      this.cliente.pessoa.contacts.forEach((contact, index) => {
        if (contact.value && String(contact.value).length > 2) {
          contacts.push(contacts)
        }
      })
      data.pessoa.contacts = contacts

      data.pessoa.addresses.map((address) => {
        if (address.cityId && address.cityId.id) {
          address.cityId = address.cityId.id
        }
        if (address.city && address.city.label) {
          address.city = address.city.label
        }
      })

      data.pessoa.documents && data.pessoa.documents.map((document) => {
        if (document.type && document.type.id) {
          document.type = document.type.id
        }
      })

      if (data.pessoa.type === TIPO_PESSOA_JURIDICA) {
      }

      data.mensalidade = convertRealToMoney(data.mensalidade)

      try {
        //data.configuracao = this.$refs.configEditor.innerText
        data.configuracao = JSON.stringify(data.configuracao)
      } catch (e) {
        alert('Configurações inválida')
        return
      }

      this.status = 'Processando dados do cliente'
      let method = data.id ? updateCliente(data.id, data) : newCliente(data)
      method
          .then(({data}) => {
            let isNew = !!!this.cliente.id
            this.cliente.id = data.id
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                // this.$refs.timer.start()
                this.loading = false
                this.success = false
                if (this.instalar) {
                  this.startSetup()
                } else {
                  let title = 'Registro de cliente'
                  let message = 'Cliente registrado com sucesso!'
                  if (!isNew) {
                    title = 'Cadastro de cliente'
                    message = 'Cliente atualizado com sucesso!'
                  }
                  this.$uloc.dialog({
                    title: title,
                    message: message,
                    type: 'success'
                  })
                  this.load()
                }
                // this.load()
                this.$uloc.window.emit(this.$root.wid, 'update', this.cliente.id)
              })
            }

            cbSuccess()

          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            // this.errors = this.systemError(error)
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
              // this.error = true
            })
          })
    },
    adicionarEndereco() {
      this.cliente.pessoa.addresses.push(JSON.parse(JSON.stringify(mockEndereco)))
    },
    removeEndereco(endereco) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um endereço',
        message: 'Você tem certeza que deseja remover este endereço do cadastro do cliente? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (endereco.id) {
          this.removeItem('enderecos', this.cliente.pessoa.id, endereco.id)
        }
        this.cliente.pessoa.addresses.splice(this.cliente.pessoa.addresses.indexOf(endereco), 1)
      }).catch(() => {
      })
    },
    getTelefoneLabel(index) {
      if (index === 0) {
        return 'Celular:'
      } else if (index === 1) {
        return 'Residencial:'
      }
      if (index === 2) {
        return 'Comercial:'
      }
      return ''
    },
    adicionarTelefone() {
      this.cliente.pessoa.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
    },
    removeTelefone(telefone) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um telefone',
        message: 'Você tem certeza que deseja remover este telefone do cadastro do cliente? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (telefone.id) {
          this.removeItem('telefones', this.cliente.pessoa.id, telefone.id)
        }
        this.cliente.pessoa.phoneNumbers.splice(this.cliente.pessoa.phoneNumbers.indexOf(telefone), 1)
      }).catch(() => {
      })
    },
    adicionarEmail() {
      this.cliente.pessoa.emails.push(JSON.parse(JSON.stringify(mockEmail)))
    },
    removeEmail(email) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um e-mail',
        message: 'Você tem certeza que deseja remover este e-mail do cadastro do cliente? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (email.id) {
          this.removeItem('emails', this.cliente.pessoa.id, email.id)
        }
        this.cliente.pessoa.emails.splice(this.cliente.pessoa.emails.indexOf(email), 1)
      }).catch(() => {
      })
    },
    adicionarContato() {
      this.cliente.pessoa.contacts.push(JSON.parse(JSON.stringify(mockContato)))
    },
    removeContato(contato) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um contato',
        message: 'Você tem certeza que deseja remover este contato do cadastro do cliente? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (contato.id) {
          this.removeItem('contatos', this.cliente.pessoa.id, contato.id)
        }
        this.cliente.pessoa.contacts.splice(this.cliente.pessoa.contacts.indexOf(contato), 1)
      }).catch(() => {
      })
    },
    removeItem(type, person, object) {
      removePersonObject(type, person, object).then(({data}) => {
        console.log(data)
      })
          .catch(error => {
            console.log(error)
            this.$nextTick(() => {
              this.alertApiError(error)
              // this.error = true
            })
          })
    },
    deleteImagemEvent() {
      this.deleteImagem = true
    },
    updateImagem(file) {
      this.$imagem = file
    },
    startSetup() {
      if (!this.instalar) return
      setup(this.cliente.id)
          .then(response => {
            this.tabActive = 'setup'
            this.$nextTick(() => {
              this.$refs.tab.updateActive()
            })
            this.setup = true
            this.setupEnd = 'in-progress'
            this.$logBufferInterval = setInterval(() => {
              this.setupTempo++
              setupLogBuffer(this.cliente.id)
                  .then(({data}) => {
                    this.buffer = data
                  })
                  .catch(error => {
                    console.log(error)
                  })
              const find = this.buffer.find(b => {
                return String(b.message).includes('[OK] Aplicação configurada')
              })
              if (find) {
                this.$logBufferInterval && clearInterval(this.$logBufferInterval)
                this.setupEnd = 'success'
              }
            }, 5000)
            // @TODO Setup timout
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  },
  components: {
    ClienteFinanceiro,
    TipoPessoaSelect,
    TipoContatoSelect,
    TipoEmailSelect,
    TipoTelefoneSelect,
    UfSelect,
    CidadeSelect,
    TipoEnderecoSelect,
    PlanoSelect,
    ServidorSelect,
    ECol,
    ERow,
    StatusSelect,
    DefaultAsyncSelect,
    // WindowSuccess,
    // TimerCloseWindow,
    // WindowLoading,
    WindowFooter,
    WindowBody,
    // WindowHeader,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    ErpLabel,
    // UEditor,
    // URadio
    ErpSelect,
    DatetimeInput,
    ErpCheckbox,
    Endereco,
    UCheckbox,
    CodeEditor
  }
}
</script>

<style lang="stylus">
.fin-cliente {
  .fin-totais {
    font-size 14px
    text-transform uppercase
  }
  .tr-fin-pago {
    td {
      opacity 0.5
    }
  }
}
</style>
